@font-face {
    font-family: 'Literal Bold';
    src: url('#{$path}/fonts/Literal/LiteralBold.eot');
    src: url('#{$path}/fonts/Literal/LiteralBold.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/fonts/Literal/LiteralBold.woff2') format('woff2'),
    url('#{$path}/fonts/Literal/LiteralBold.woff') format('woff'),
    //  url('http://localhost:8100#{$path}/fonts/Literal/LiteralBold.otf') format('otf'),
    url('#{$path}/fonts/Literal/LiteralBold.ttf') format('truetype'),
    url('#{$path}/fonts/Literal/LiteralBold.svg#literalbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Literal Light';
    src: url('#{$path}/fonts/Literal/LiteralLight.eot');
    src: url('#{$path}/fonts/Literal/LiteralLight.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/fonts/Literal/LiteralLight.woff2') format('woff2'),
    url('#{$path}/fonts/Literal/LiteralLight.woff') format('woff'),
    //  url('#{$path}/fonts/Literal/LiteralLight.otf') format('otf'),
    url('#{$path}/fonts/Literal/LiteralLight.ttf') format('truetype'),
    url('#{$path}/fonts/Literal/LiteralLight.svg#literallight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Literal';
    src: url('#{$path}/fonts/Literal/LiteralRegular.eot');
    src: url('#{$path}/fonts/Literal/LiteralRegular.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/fonts/Literal/LiteralRegular.woff2') format('woff2'),
    url('#{$path}/fonts/Literal/LiteralRegular.woff') format('woff'),
    //  url('#{$path}/fonts/Literal/LiteralRegular.otf') format('otf'),
    url('#{$path}/fonts/Literal/LiteralRegular.ttf') format('truetype'),
    url('#{$path}/fonts/Literal/LiteralRegular.svg#literalregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* manrope-200 - cyrillic_latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 200;
    src: url('#{$path}/fonts/Manrope/manrope-v15-cyrillic_latin-200.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-300 - cyrillic_latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    src: url('#{$path}/fonts/Manrope/manrope-v15-cyrillic_latin-300.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-regular - cyrillic_latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    src: url('#{$path}/fonts/Manrope/manrope-v15-cyrillic_latin-regular.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-500 - cyrillic_latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    src: url('#{$path}/fonts/Manrope/manrope-v15-cyrillic_latin-500.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-600 - cyrillic_latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    src: url('#{$path}/fonts/Manrope/manrope-v15-cyrillic_latin-600.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-700 - cyrillic_latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    src: url('#{$path}/fonts/Manrope/manrope-v15-cyrillic_latin-700.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-800 - cyrillic_latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    src: url('#{$path}/fonts/Manrope/manrope-v15-cyrillic_latin-800.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

h1 {
    font-size: 54px;
}

h6 {
    &:not(:first-child) {
        margin-top: 45px;
    }
}

.nc-uppercase {
    &_input {
        text-transform: uppercase;

        ion-input,
        input {
            text-transform: uppercase;
        }
    }
}

.nc-sign_star {
    font-size: 18px;

    .nc-input-group__field--has-value~.nc-input-group__label &,
    .nc-input-group__field:focus~.nc-input-group__label &,
    .item-has-focus.label-floating.sc-ion-label-md-h &,
    .item-has-focus .label-floating.sc-ion-label-md-h &,
    .item-has-placeholder.label-floating.sc-ion-label-md-h &,
    .item-has-placeholder .label-floating.sc-ion-label-md-h &,
    .item-has-value.label-floating.sc-ion-label-md-h &,
    .item-has-value .label-floating.sc-ion-label-md-h &,
    .nc-has-mask.label-floating.sc-ion-label-md-h &,
    .nc-has-mask .label-floating.sc-ion-label-md-h &,
    .label-stacked.sc-ion-label-md-h & {
        display: inline-block;
        transform: scale(1.345);
        transform-origin: left;
    }
}