// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    // --ion-color-primary: #1772C6;
    // --ion-color-secondary: #0cd1e8;
    // --ion-color-tertiary: #7044ff;
    // --ion-color-success: #29C438;
    // --ion-color-warning: #FFA755;
    // --ion-color-danger: #F52C50;
    // --ion-color-dark: #222428;
    // --ion-color-medium: #989aa2;
    // --ion-color-light: #f4f5f8;
    // --ion-color-status-active: #1772C6;
    --side-max-width: 340px;
    --dominant-color-hue: 0;
    --dominant-color-sat: 100%;
    --dominant-color-l: 50%;

    /** primary **/
    --ion-color-primary: #1772c6;
    --ion-color-primary-rgb: 23, 114, 198;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #1464ae;
    --ion-color-primary-tint: #2e80cc;

    /** secondary **/
    --ion-color-secondary: #4ea1ed;
    --ion-color-secondary-rgb: 78, 161, 237;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #458ed1;
    --ion-color-secondary-tint: #60aaef;

    /** tertiary **/
    --ion-color-tertiary: #1789f2;
    --ion-color-tertiary-rgb: 23, 137, 242;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #1479d5;
    --ion-color-tertiary-tint: #2e95f3;

    /** success **/
    --ion-color-success: #29c438;
    --ion-color-success-rgb: 41, 196, 56;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #24ac31;
    --ion-color-success-tint: #3eca4c;

    /** warning **/
    --ion-color-warning: #ffa755;
    --ion-color-warning-rgb: 255, 167, 85;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0934b;
    --ion-color-warning-tint: #ffb066;

    /** danger **/
    --ion-color-danger: #f52c50;
    --ion-color-danger-rgb: 245, 44, 80;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d82746;
    --ion-color-danger-tint: #f64162;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** statuses **/
    --ion-color-status-todo: #ffda65;
    --ion-color-status-todo-rgb: 255, 218, 101;
    --ion-color-status-todo-contrast: #000000;
    --ion-color-status-todo-contrast-rgb: 0, 0, 0;
    --ion-color-status-todo-shade: #e0c059;
    --ion-color-status-todo-tint: #ffde74;

    --ion-color-status-active: #1772c6;
    --ion-color-status-active-rgb: 23, 114, 198;
    --ion-color-status-active-contrast: #ffffff;
    --ion-color-status-active-contrast-rgb: 255, 255, 255;
    --ion-color-status-active-shade: #1464ae;
    --ion-color-status-active-tint: #2e80cc;

    --ion-color-status-pending: #b3c6e7;
    --ion-color-status-pending-rgb: 179, 198, 231;
    --ion-color-status-pending-contrast: #222428;
    --ion-color-status-pending-contrast-rgb: 0, 0, 0;
    --ion-color-status-pending-shade: #9eaecb;
    --ion-color-status-pending-tint: #bbcce9;

    --ion-color-status-unactive: #d8d8d8;
    --ion-color-status-unactive-rgb: 216, 216, 216;
    --ion-color-status-unactive-contrast: #222428;
    --ion-color-status-unactive-contrast-rgb: 0, 0, 0;
    --ion-color-status-unactive-shade: #bebebe;
    --ion-color-status-unactive-tint: #dcdcdc;

    --ion-color-status-good: #a9d18d;
    --ion-color-status-good-rgb: 169, 209, 141;
    --ion-color-status-good-contrast: #222428;
    --ion-color-status-good-contrast-rgb: 0, 0, 0;
    --ion-color-status-good-shade: #95b87c;
    --ion-color-status-good-tint: #b2d698;

    --ion-color-status-warning: #f4b082;
    --ion-color-status-warning-rgb: 244, 176, 130;
    --ion-color-status-warning-contrast: #222428;
    --ion-color-status-warning-contrast-rgb: 0, 0, 0;
    --ion-color-status-warning-shade: #d79b72;
    --ion-color-status-warning-tint: #f5b88f;

    --tooltip-thingy-height: 0.5em;
}
/** status + transit **/
.ion-color-draft {
    --ion-color-base: var(--ion-color-status-todo) !important;
    --ion-color-base-rgb: var(--ion-color-status-todo-rgb) !important;
    --ion-color-contrast: var(--ion-color-status-todo-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-status-todo-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-status-todo-shade) !important;
    --ion-color-tint: var(--ion-color-status-todo-tint) !important;
}

.ion-color-accepted,
.ion-color-accept {
    --ion-color-base: var(--ion-color-status-good) !important;
    --ion-color-base-rgb: var(--ion-color-status-good-rgb) !important;
    --ion-color-contrast: var(--ion-color-status-good-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-status-good-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-status-good-shade) !important;
    --ion-color-tint: var(--ion-color-status-good-tint) !important;
}
.ion-color-return {
    --ion-color-base: var(--ion-color-status-active) !important;
    --ion-color-base-rgb: var(--ion-color-status-active-rgb) !important;
    --ion-color-contrast: var(--ion-color-status-active-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-status-active-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-status-active-shade) !important;
    --ion-color-tint: var(--ion-color-status-active-tint) !important;
}
.ion-color-deleted,
.ion-color-cancelled,
.ion-color-cancel {
    --ion-color-base: var(--ion-color-status-unactive) !important;
    --ion-color-base-rgb: var(--ion-color-status-unactive-rgb) !important;
    --ion-color-contrast: var(--ion-color-status-unactive-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-status-unactive-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-status-unactive-shade) !important;
    --ion-color-tint: var(--ion-color-status-unactive-tint) !important;
}
.ion-color-declined,
.ion-color-delete,
.ion-color-decline,
.ion-color-revoke {
    --ion-color-base: var(--ion-color-status-warning) !important;
    --ion-color-base-rgb: var(--ion-color-status-warning-rgb) !important;
    --ion-color-contrast: var(--ion-color-status-warning-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-status-warning-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-status-warning-shade) !important;
    --ion-color-tint: var(--ion-color-status-warning-tint) !important;
}
.ion-color-inprocess,
.ion-color-send,
.ion-color-resend,
.ion-color-update {
    --ion-color-base: var(--ion-color-status-pending) !important;
    --ion-color-base-rgb: var(--ion-color-status-pending-rgb) !important;
    --ion-color-contrast: var(--ion-color-status-pending-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-status-pending-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-status-pending-shade) !important;
    --ion-color-tint: var(--ion-color-status-pending-tint) !important;
}

/*
 * App Theme
 * ----------------------------------------------------------------------------
 * Ionic apps can have different themes applied, which can
 * then be further customized. These variables come last
 * so that the above variables are used by default.
 */

/*
 * Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme {
    --ion-color-primary: #1772c6;
    --ion-color-primary-rgb: 23, 114, 198;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #1464ae;
    --ion-color-primary-tint: #2e80cc;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #29c438;
    --ion-color-success-rgb: 41, 196, 56;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #24ac31;
    --ion-color-success-tint: #3eca4c;

    /** warning **/
    --ion-color-warning: #ffa755;
    --ion-color-warning-rgb: 255, 167, 85;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0934b;
    --ion-color-warning-tint: #ffb066;

    /** danger **/
    --ion-color-danger: #f52c50;
    --ion-color-danger-rgb: 245, 44, 80;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d82746;
    --ion-color-danger-tint: #f64162;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
}

.dark-theme-ext.md,
.dark-theme-ext.ios,
.dark-theme-ext {
    --ion-background-color: var(--dominant-color, #121212);
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: var(--dominant-color-d5, #222222);
    --ion-toolbar-background: var(--dominant-color, #1f1f1f);
    --ion-tab-bar-background: var(--dominant-color, #1f1f1f);

    --ion-item-background: var(--dominant-color, #1e1e1e);

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;

    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;

    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;

    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;

    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;

    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
}

.eco-theme-dominant-ext.md,
.eco-theme-dominant-ext.ios,
.eco-theme-dominant-ext {
    --dominant-color: hsl(var(--dominant-color-hue), var(--dominant-color-sat), var(--dominant-color-l));
    --dominant-color-d5: hsl(var(--dominant-color-hue), var(--dominant-color-sat), calc(var(--dominant-color-l) - 5%));
    --dominant-color-d10: hsl(
        var(--dominant-color-hue),
        var(--dominant-color-sat),
        calc(var(--dominant-color-l) - 10%)
    );
    --dominant-color-d15: hsl(
        var(--dominant-color-hue),
        var(--dominant-color-sat),
        calc(var(--dominant-color-l) - 15%)
    );
    --dominant-color-d20: hsl(
        var(--dominant-color-hue),
        var(--dominant-color-sat),
        calc(var(--dominant-color-l) - 20%)
    );
    --dominant-color-d25: hsl(
        var(--dominant-color-hue),
        var(--dominant-color-sat),
        calc(var(--dominant-color-l) - 25%)
    );
    --dominant-color-d30: hsl(
        var(--dominant-color-hue),
        var(--dominant-color-sat),
        calc(var(--dominant-color-l) - 30%)
    );
    --dominant-color-d35: hsl(
        var(--dominant-color-hue),
        var(--dominant-color-sat),
        calc(var(--dominant-color-l) - 35%)
    );
    --dominant-color-d40: hsl(
        var(--dominant-color-hue),
        var(--dominant-color-sat),
        calc(var(--dominant-color-l) - 40%)
    );
    --dominant-color-d45: hsl(
        var(--dominant-color-hue),
        var(--dominant-color-sat),
        calc(var(--dominant-color-l) - 45%)
    );
    --dominant-color-d50: hsl(
        var(--dominant-color-hue),
        var(--dominant-color-sat),
        calc(var(--dominant-color-l) - 50%)
    );
}

/*
 * Eco Theme
 * ----------------------------------------------------------------------------
 */

.eco-theme {
    --side-max-width: 200px;
    --ion-padding: 10px;
    --ion-margin: 10px;
    --ion-font-family: 'Literal light';

    --dominant-color-hue: 125;
    --dominant-color-sat: 15%;
    --dominant-color-l: 30%;

    --ion-color-primary: #557755;
    --ion-color-primary-rgb: 85, 119, 85;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #4b694b;
    --ion-color-primary-tint: #668566;

    --ion-color-secondary: #5b7f8f;
    --ion-color-secondary-rgb: 91, 127, 143;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #50707e;
    --ion-color-secondary-tint: #6b8c9a;

    --ion-color-tertiary: #83614e;
    --ion-color-tertiary-rgb: 131, 97, 78;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #735545;
    --ion-color-tertiary-tint: #8f7160;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}

/*
 * Hard Theme
 * ----------------------------------------------------------------------------
 */

.hard-theme {
    --ion-color-primary: #55443e;
    --ion-color-primary-rgb: 85, 68, 62;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #4b3c37;
    --ion-color-primary-tint: #665751;

    --ion-color-secondary: #707097;
    --ion-color-secondary-rgb: 112, 112, 151;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #636385;
    --ion-color-secondary-tint: #7e7ea1;

    --ion-color-tertiary: #726181;
    --ion-color-tertiary-rgb: 114, 97, 129;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #645572;
    --ion-color-tertiary-tint: #80718e;

    --ion-color-success: #2dd464;
    --ion-color-success-rgb: 45, 212, 100;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #28bb58;
    --ion-color-success-tint: #42d874;

    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 240, 65, 65;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}

/*
 * Med Theme
 * ----------------------------------------------------------------------------
 */

.med-theme {
    --ion-color-primary: #039b94;
    --ion-color-primary-rgb: 3, 155, 148;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #038882;
    --ion-color-primary-tint: #1ca59f;

    --ion-color-secondary: #43a7ff;
    --ion-color-secondary-rgb: 67, 167, 255;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #3b93e0;
    --ion-color-secondary-tint: #56b0ff;

    --ion-color-tertiary: #9c7fc7;
    --ion-color-tertiary-rgb: 156, 127, 199;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #8970af;
    --ion-color-tertiary-tint: #a68ccd;

    --ion-color-success: #b7d124;
    --ion-color-success-rgb: 183, 209, 36;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #a1b820;
    --ion-color-success-tint: #bed63a;

    --ion-color-warning: #ffb55a;
    --ion-color-warning-rgb: 255, 181, 90;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e09f4f;
    --ion-color-warning-tint: #ffbc6b;

    --ion-color-danger: #ff5345;
    --ion-color-danger-rgb: 255, 83, 69;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #e0493d;
    --ion-color-danger-tint: #ff6458;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}

/*
 * iOS Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.ios {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #1c1c1c;
    --ion-item-background-activated: #313131;
}

/*
 * Material Design Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.md {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
}
